import React, {useEffect, useState} from 'react'
import Layout from '../layouts'
import { Link } from 'gatsby'
import { Collapse } from "react-bootstrap"
import CategoryContent from '../components/categorycontent/categorycontent.js'
import Category from '../components/ecwid/category'
import SideNavCat from '../components/sidenav'
import ProductTile from "../components/ecwid/productTile"
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc"
import { LazyLoadComponent } from 'react-lazy-load-image-component';
const _ = require("lodash")
const slugify = require("slugify")

const ProductShop=({ data, location })=> {
	const [categories, setCategories] = useState([])
  const [activeCategory, setActiveCategory] = useState(null)
  const [childCategory, setChildCategory] = useState(null)
	
	const product = data.allSitePage.nodes
	const ecwidCategory = data.allEcwidCategories.nodes

	const filteredProduct = product.filter(item => item.context?.categoryIds?.includes(46059013))


	console.log(ecwidCategory)

	return (
			<Layout
				pathname={location.pathname}
				head={{
					name: "Shop",
				}}
			>
				<div className="product product-category">
					<div className="container">
						<ol className="small breadcrumb justify-content-center justify-content-md-start border-bottom bg-transparent">
				          <li className="breadcrumb-item">
				            <Link to="/">
				              <span>Home</span>
				            </Link>
				          </li>
				          <li className="breadcrumb-item">
				            <Link to="/" aria-current="page" className="btn-link disabled">
				              <span>Shop</span>
				            </Link>
				          </li>
				        </ol>
							
				
				
				
					
						
							
								<div className="row py-5">
									<div className="col-sm-5 col-md-4 col-lg-3 mb-3 mb-md-0">
										<Category data={ecwidCategory}/>
									</div>
									<div className="col-sm-7 col-md-8 col-lg-9">
										<div className="row">
		                  {filteredProduct.map((item, index) => {
		                    return (
	                        <div className="col-md-6 col-lg-4 mb-5" key={index}>
		                         <LazyLoadComponent delayTime={1000}>
		                          <ProductTile
										            link={item.context.url}
										            image={item.context.originalImageUrl}
										            name={item.context.name}
										            price={item.context.defaultDisplayedPriceFormatted}
										            inStock={item.context.inStock}
										          />
		                         </LazyLoadComponent>
		                      </div>
		                    )
		                  })}
		                </div>
									</div>
								</div>
							
						
							</div>
					
				
				</div>
			</Layout>
		)
}


export default ProductShop


export const productShopQuery = graphql`
	query productShopQuery {
		allSitePage(filter: {context: {enabled: {eq: true}}}) {
	    nodes {
	      context {
	      	categoryIds
	        categories {
	          id
	        }
	        defaultCategoryId
	        sku
	        thumbnailUrl
	        unlimited
	        inStock
	        name
	        price
	        priceInProductList
	        defaultDisplayedPrice
	        defaultDisplayedPriceFormatted
	        isShippingRequired
	        weight
	        url
	        created
	        updated
	        createTimestamp
	        updateTimestamp
	        productClassId
	        enabled
	        warningLimit
	        fixedShippingRateOnly
	        fixedShippingRate
	        defaultCombinationId
	        imageUrl
	        smallThumbnailUrl
	        hdThumbnailUrl
	        originalImageUrl
	        description
	        seoTitle
	        seoDescription
	        showOnFrontpage
	        isSampleProduct
	        googleItemCondition
	        isGiftCard
	        discountsAllowed
	        nameYourPriceEnabled
	        productCondition
	        quantity
	        parentId
	        orderBy
	        productCount
	        isSampleCategory
	      }
	    }
	  }
	  allEcwidCategories {
	    nodes {
	      ecwid_id
	      id
	      parentId
	      name
	      url
	    }
	  }
	}
`